import React from "react";
import { Link } from "gatsby";
import useIsInViewport from "../../hooks/useIsInViewport";
import { isEven } from "../../helpers/helpers";
import Seo from "../../components/seo";
import joalis from "../../img/hp/joalis.png";
import boxSmall from "../../img/hp/krabice-mala.jpg";
import mpm from "../../img/hp/mpm.png";
import code8 from "../../img/hp/nav1.jpg";
import ourWork from "../../img/hp/nav2.jpg";
import testCoverage from "../../img/hp/test-coverage.png";
import nanits from "../../img/hp/nanits.jpg";
import prague from "../../img/hp/nav4.jpg";

const news = [
    {
        imgSrc: joalis,
        alt: "Joalis",
        ribbon: "Spuštěno!",
        link: "https://www.joalis.cz",
        linkText: "Joalis website",
        text: "Joalis je česká firma založená v roce 1999. Vyrábí zcela unikátní přírodní doplňky stravy, ve kterých se spojuje působení bylin se sílou informace. Jeden z projektů, který jsme měli tu čest dělat takzvaně od&nbsp;A&nbsp;do&nbsp;Z.",
        ctaText: "Navštivte joalis.cz",
    },
    {
        imgSrc: boxSmall,
        alt: "Box small",
        ribbon: "Novinka",
        link: "https://www.shop8.cz",
        linkText: "Když je vám krabice malá",
        text: "Mid-size e-commerce řešení na míru pro zákazníky, kteří růstem překonali krabicová řešení a nyní vyžadují více individuální přístup za rozumné náklady.",
        ctaText: "Vyzkoušejte náš eshop",
    },
    {
        imgSrc: mpm,
        alt: "Mobile Phone Museum",
        ribbon: "Spuštěno!",
        link: "https://www.mobilephonemuseum.com/catalogue/",
        linkText: "Museum mobilních telefonů",
        text: 'Ve spolupráci britských a českých sběratelů a nadšenců pak vznikl úžasný projekt největší sbírky mobilních telefonů na světě. Krásný funkční design navržený talentovanými lidmi z <a href="https://www.toman-design.com">Toman Design</a> jsme přivedli k životu pomocí přehlednáho CMS a generátoru statických stránek.',
        ctaText: "Ponořte se do historie",
    },
];
const intro = [
    {
        imgSrc: ourWork,
        alt: "Our's work",
        link: "/cz/co-umime/",
        linkText: "Tým, o který se nemusíte starat",
        text: "Můžete mít k ruce partu špičkových programátorů, které si najmete podle potřeby. Zvládneme sami vymazlit celý projekt nebo vám rádi půjčíme pár profíků jen na vybranou část projektu.",
        ctaText: "Co umíme &gt;",
    },
    {
        imgSrc: code8,
        alt: "Code8",
        link: "/cz/jak-pracujeme/",
        linkText: "Neděláme poloviční práci",
        text: "Ať už jde o čistý design, sametově hladkou animaci či chytrý algoritmus - vždy se snažíme o dokonalost. V případě potřeby dokážeme rozšířit tým o další profesionály, kteří nám pomohou zvládnout větší projekt či vámi požadovanou technologii.",
        ctaText: "Jak pracujeme &gt;",
    },
    {
        imgSrc: testCoverage,
        alt: "Test coverage",
        link: "/cz/jak-pracujeme/",
        linkText: "Máme rádi věci pod kontrolou",
        text: "Snažíme se chybám předcházet. Proto jsme zvyklí naše produkty poctivě testovat už od začátku vývoje. Vyzkoušeli jsme různé strategie a proto umíme testovat efektivně a bez zbytečného prodražování.",
        ctaText: "Jak testujeme &gt",
    },
    {
        imgSrc: nanits,
        alt: "Nanits",
        link: "/cz/o-nas/",
        linkText: "Experti na dálkové ovládání",
        text: "Již od roku 2011 pracujeme “na dálku” pro agentury, start-upy a rozličné vývojářské týmy u nás a v Anglii. Díky moderním komunikačním nástrojům můžete nejen sledovat naši práci, ale také náklady.",
        ctaText: "O nás &gt;",
    },
    {
        imgSrc: prague,
        alt: "Prague",
        link: "/cz/kontakt/",
        linkText: "Máte dotaz?",
        text: " Ozvěte se a dejte vědět, s čím vám můžeme pomoci.<br />Domluvíme si schůzku nebo naplánujeme hovor přes Skype.",
        ctaText: "Napište nám &gt;",
    },
];

const Index = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <div>
                <Seo title="Vítejte" lang="cs" />
                <h2 className="line">Co je nového?</h2>
                <div className="news">
                    {news.map((item, index) => (
                        <article className="article" ref={setRef} data-observer={index} key={item.alt}>
                            {isEven(index) && (
                                <div className="left animate-2">
                                    <img src={item.imgSrc} alt={item.alt} />
                                </div>
                            )}
                            <div className={isEven(index) ? "right" : "left"}>
                                <h4 className={`ribbon${isEven(index) ? " animate-3" : " animate-2"}`}>{item.ribbon}</h4>
                                <h3 className={`${isEven(index) ? "animate-4" : "animate-3"}`}>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        {item.linkText}
                                    </a>
                                </h3>
                                <p className={`${isEven(index) ? "animate-5" : "animate-4"}`} dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                <p className={`${isEven(index) ? "animate-6" : "animate-5"}`}>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        {item.ctaText}
                                    </a>
                                </p>
                            </div>
                            {!isEven(index) && (
                                <div className="right animate-6">
                                    <img src={item.imgSrc} alt={item.alt} />
                                </div>
                            )}
                        </article>
                    ))}
                </div>
                <h2 className="line" ref={setRef} data-observer={4}>
                    A kdo vlastně jsme?
                </h2>
                <div className="intro">
                    {intro.map((item, index) => (
                        <article className="article" ref={setRef} data-observer={index + 5} key={item.alt}>
                            {!isEven(index) && (
                                <div className="left animate-2">
                                    <img src={item.imgSrc} alt="Code8" />
                                </div>
                            )}
                            <div className={`${isEven(index) ? "left animate-3" : "right animate-2"}`}>
                                <h3 className={`${isEven(index) ? "animate-4" : "animate-3"}`}>
                                    <Link to={item.link}>{item.linkText}</Link>
                                </h3>
                                <p className={`${isEven(index) ? "animate-5" : "animate-4"}`}>{item.text}</p>
                                <p className={`${isEven(index) ? "animate-6" : "animate-5"}`}>
                                    <Link to={item.link} dangerouslySetInnerHTML={{ __html: item.ctaText }}></Link>
                                </p>
                            </div>
                            {isEven(index) && (
                                <div className="right animate-6">
                                    <img src={item.imgSrc} alt="Code8" />
                                </div>
                            )}
                        </article>
                    ))}
                </div>
            </div>
        </>
    );
};
export default Index;
